.photo-card {
  transition: transform 0.3s ease;
}
.photo-card:hover {
  transform: translateY(-4px);
}
.dropzone {
  background-color: #f3f4f6;
  border: 2px dashed #d1d5db;
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 20px;
  cursor: pointer;
  text-align: center;
}

.shimmer {
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: shimmer 1s infinite;
}

@keyframes shimmer {
  0% {
    background-position: -100% 0;
  }
  100% {
    background-position: 100% 0;
  }
}
.animate-shimmer {
  animation: shimmer 2s linear infinite;
}

@keyframes progress {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}

.animate-progress {
  animation: progress 2s linear infinite;
}
.left-side-nav {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  width: 25%;
  padding-right: 1rem;
}

.content-area {
  width: 75%;
  padding-left: 1rem;
}

/* Custom Styles for SweetAlert2 */
.swal2-title {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  color: #333333;
}

.swal2-content {
  font-family: 'Georgia', serif;
  color: #555555;
}

.swal2-confirm {
  background-color: #3085d6;
  color: #ffffff;
  border: 0;
  box-shadow: none;
  font-size: 16px;
  font-weight: bold;
  border-radius: 5px;
}

.swal2-cancel {
  background-color: #d33;
  color: #ffffff;
  border: 0;
  box-shadow: none;
  font-size: 16px;
  font-weight: bold;
  border-radius: 5px;
}
