@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'https://cdnjs.cloudflare.com/ajax/libs/tailwindcss/2.2.19/tailwind.min.css';

.card {
  perspective: 1500px;
  transition: transform 0.5s;
}

.card:hover {
  transform: translateY(-10px) rotateX(20deg);
}

.card:hover .proceed-btn {
  background-color: #4b5563;
}

.modal{
width:100%!important;
}
/* Add this in your global stylesheet */
.theme-card-container > div:nth-child(n+6) {
  margin-top: 1rem; /* Adjust the spacing as needed */
}
