/* Existing CSS */
.custom-swiper-button-next, .custom-swiper-button-prev {
    z-index: 10;  
    cursor: pointer;
}

.custom-swiper-button-prev:hover, .custom-swiper-button-next:hover {
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 50%;
}

/* Updated CSS */
.single-card-container .swiper-wrapper {
    justify-content: center;
}

/* Your existing CSS for .single-card-container .swiper-slide-active is good, you can keep it. */
