@keyframes shimmer {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.animate-shimmer {
  animation: shimmer 1.5s ease-in-out infinite;
  background-color: rgba(128, 128, 128, 0.2);
}

/* Add this to your CSS file or style tag */
.shimmer-effect {
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite linear;
}

@keyframes shimmer2 {
  0% {
    background-position: -100% 0;
  }
  100% {
    background-position: 100% 0;
  }
}
